import Header from '@components/Layout/Header';
import Link from '@components/Link';
import background from '@images/HomepageBackground.svg';
import clsx from 'clsx';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import React, { FormEvent, useState } from 'react';
import { IconContext } from 'react-icons';
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaTwitterSquare, FaRegPaperPlane } from 'react-icons/fa';

import styles from './index.module.css';

const icons = {
    facebook: {
        icon: <FaFacebook />,
        path: 'https://www.facebook.com/HackCU/',
    },
    twitter: {
        icon: <FaTwitterSquare />,
        path: 'https://twitter.com/hackcu',
    },
    instagram: {
        icon: <FaInstagramSquare />,
        path: 'https://www.instagram.com/hackcu',
    },
    linkedin: {
        icon: <FaLinkedin />,
        path: 'https://www.linkedin.com/company/hackcu/',
    },
};

const IndexPage: React.FC = () => {
    const [email, setEmail] = useState("");

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('entry.579390246', email);
        fetch(
            'https://docs.google.com/forms/u/0/d/e/1FAIpQLSeuuOjah5CTBaw4fIl7yGUyi9peLC4LsjMsBRz6Szs9FXThMg/formResponse',
            {
                method: 'post',
                body: formData,
            },
        );
        // console.log("RESPONSE:", response);
        setEmail('');

    };

    return (
        <>
            <GatsbySeo title="Home" />
            <div className="relative h-screen overflow-hidden">
                <Header />
                <img
                    className="absolute bottom-0"
                    src={background as any}
                    alt="Background image of the Boulder flatirons."
                    style={{
                        zIndex: -2,
                    }}
                />
                <div className="ml-3 md:ml-4 mr-3 md:mr-0 text-center sm:text-left">
                    <h1 className={clsx('font-bold mb-2', styles.title)}>HACK</h1>
                    <div className="flex flex-col sm:flex-row">
                        <h1 className={clsx('font-bold', styles.title)}>CU</h1>
                        <div className="mt-3 md:mt-2 pl-0 md:pl-3">
                            <form id="email-form" acceptCharset="utf-8" onSubmit={onSubmit}>
                                <div className="flex">
                                    <input
                                        id="email-input"
                                        type="email"
                                        name="email"
                                        placeholder="example@colorado.edu"
                                        className="text-black bg-white mr-1 flex-grow rounded-md focus:ring focus:ring-primary-500 focus-border-transparent"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        required
                                        aria-labelledby="input-description"
                                    />
                                    <button
                                        id="email-submit"
                                        type="submit"
                                        aria-label="submit"
                                        className="px-3 py-2 bg-primary-600 rounded-md"
                                    >
                                        <FaRegPaperPlane />
                                    </button>
                                </div>
                            </form>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    marginBottom: '20px',
                                }}
                            >
                                {/* <div className="mt-6 text-center">
                                    <Link to="/register" outside>
                                        <button
                                            className="font-bold text-xl cursor-pointer px-3 py-2 text-white bg-red-700	 rounded-md"
                                            style={{
                                                boxShadow: '0 0 15px 5px rgba(249, 6, 6, 0.75)', // Glowing effect
                                            }}
                                        >
                                            Register for HackCU 11, then the AMD AI PC Workshop!
                                        </button>
                                    </Link>{' '}
                                    <br></br>
                                </div> */}
                            </div>
                            <div className="text-center">
                                <p>Enter your email to be the first to know about HackCU events, updates, and more.</p>
                                <div className="mt-2 mx-auto flex space-x-3 justify-center">
                                    <IconContext.Provider value={{ color: 'white', size: '35px' }}>
                                        {Object.keys(icons).map((icon) => (
                                            <Link key={icon} outside to={icons[icon].path} aria-label={icon}>
                                                {icons[icon].icon}
                                            </Link>
                                        ))}
                                    </IconContext.Provider>
                                </div>
                                <br></br>
                                <div className="mt-6 text-center">
                                    <Link to="/join" outside>
                                        <button className="font-bold text-xl cursor-pointer px-3 py-2 text-white bg-purple-800 rounded-md">
                                            Become an organizer (2025-2026) for HackCU 12!
                                        </button>
                                    </Link>{' '}
                                    <br></br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default IndexPage;
